<template>
  <div class="reset-password" @click="emitCloseSearch">
    <popup-base @clickOnBackground="emitCloseSearch">
      <div class="w-1/2 p-4 bg-white reset-section rounded">
      <h3 class="text-2xl font-bold ">{{ $t('searchAccount') }}</h3>
       <h3 class="text-xl font-light mt-3">{{ $t('searchAccountText') }}</h3>
        <div class="mt-3 email-form border h-16 rounded">
          <input class="h-full w-full p-2 input-email"
                 type="email"
                 v-model="email"
                 placeholder="rema@gmail.com">
        </div>
        <div class="button-section w-full mt-3">
          <button
            class="px-3 py-5 w-full rounded font-bold text-white text-center uppercase text-center bg-green"
            @click="startSearching"
          >
            <span v-if="!startSearch"> {{ $t('search') }} </span>
            <span v-if="startSearch"><pulse-loader color="#ffffff"></pulse-loader></span>
          </button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import firebase from 'firebase'
export default {
  name: 'ResetPasswordSearch',
  components: { PulseLoader, PopupBase },
  data () {
    return {
      startSearch: false,
      email: ''
    }
  },
  methods: {
    startSearching () {
      let vm = this
      this.startSearch = true
      firebase.auth().fetchSignInMethodsForEmail(this.email)
        .then((res) => {
          if (res.toString() === 'password') {
            firebase.auth().sendPasswordResetEmail(vm.email)
              .then((e) => {
                vm.startSearch = false
                this.$emit('sucessEmail', true)
              })
              .catch((err) => {
                alert(err.toString())
              })
          } else {
            vm.$toasted.error(vm.$t('noComptMatch'))
            vm.startSearch = false
          }
        })
        .catch((err) => {
          alert(err.toString())
        })
    },
    emitCloseSearch () {
      this.$emit('searchClose', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .reset-section {
    margin: auto;
  }
  .input-email {
    background-color: $Rema-Content-Background;
  }
</style>
