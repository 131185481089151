<template>
  <div
    class="success-create"
    @click="emitCloseReset"
  >
    <popup-base @clickOnBackground="emitCloseReset">
      <div class="bg-white rounded p-5 w-1/2 m-auto">
        <h3 class="text-2xl font-bold p-2">
          {{ $t('resetEmailSendTitle') }}
        </h3>
        <div class=" p-3 rounded">
          <h3 class=" w-full font-normal success leading-10">
            {{ $t('resetEmailSendMessage') }}
          </h3>
        </div>
        <div class="button-section mt-3 w-full">
          <button
            class="px-3 py-5 w-full rounded font-bold text-white text-center uppercase text-center bg-green"
            @click="emitCloseReset"
          >
            OK
          </button>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from './popup-base'
export default {
  name: 'resetEmailSended',
  components: { PopupBase },
  methods: {
    emitCloseReset () {
      this.$emit('closeReset', false)
    }
  }
}
</script>

<style  lang="scss" scoped>
  @import "../../../assets/style/sass/variables";
</style>
