<template>
  <div class="login">
    <TheHeader
      :show-profile="false"
      class="fixed z-10"
    />
    <div class="flex">
      <div class="flex-1 pl-24 w-1/2 pt-20 md:pt-10 div-padding">
        <div class="text-5xl font-bold connect pt-32 margin-head">
          {{ $t('connect') }}
        </div>
        <div class="pt-6 text-xl">
          <span>
            {{ $t('noPage') }}
          </span>
          <span class="createPage">
            <router-link
              to="/sign-up"
              class="createPage"
            >{{ $t('createPage') }}</router-link>
          </span>
        </div>

        <div class="pt-10 w-full">
          <label class="block tracking-wide text-gray-700 text-lg mb-2">
            {{ $t('labelEmail') }}
          </label>
          <div class="flex flex-wrap items-stretch w-2/3 mb-8 input-container">
            <div class="flex -mr-px">
              <span class="flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm">
                <svg-icon
                  name="ic-email"
                  class="email-icon w-5 h-12"
                  :color="emailInputColor"
                  original
                />
              </span>
            </div>
            <input
              v-model="email"
              type="email"
              class="input flex-shrink flex-grow flex-auto bg-white -pt-3 leading-normal w-px px-1 flex-1 border border-l-0 h-12 border-gray-300 rounded rounded-l-none px-0 appearance-none relative"
              placeholder="jane@example.com"
              @input="changeEmailColr"
            >
          </div>

          <label class="block tracking-wide text-gray-700 text-lg mb-2">
            {{ $t('labelPassword') }}
          </label>
          <div class="flex flex-wrap border rounded items-stretch w-2/3 mb-8 input-container">
            <div class="flex -mr-px">
              <span
                class="flex items-center leading-normal rounded rounded-r-none   border-gray-300 px-3 h-12 whitespace-no-wrap text-grey-dark text-sm"
              >
                <svg-icon
                  name="ic-password"
                  class="email-icon w-4 h-12"
                  :color="passIconColor"
                  original
                />
              </span>
            </div>
            <input
              v-model="password"
              :type="showInput"
              class="input flex-shrink flex-grow flex-auto bg-white leading-normal w-px px-2 flex-1   h-12 border-gray-300 rounded rounded-l-none px-0 appearance-none relative"
              placeholder="password"
              @input="changeInputColor"
            >
            <span
              v-if="wantToseeConfirmPass"
              class=" cursor-pointer flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm"
              @click="hideConfPass"
            >
              <svg-icon
                name="ic-eye-open"
                class="email-icon w-5"
                :color="defaultColor"
                original
              />
            </span>
            <span
              v-if="!wantToseeConfirmPass"
              class=" cursor-pointer flex items-center leading-normal rounded rounded-r-none   px-3 h-12 whitespace-no-wrap text-grey-dark text-sm"
              @click="showConfPass"
            >
              <svg-icon
                name="ic-eye-close"
                class="email-icon w-5"
                :color="defaultColor"
                original
              />
            </span>
          </div>
          <div
            class="forgetPass text-xl cursor-pointer"
            @click="forgetPass"
          >
            {{ $t('forgetPassword') }}
          </div>
          <button
            class="buttonLogin py-3 mt-8 w-1/3 h-12 rounded text-white text-xl"
            @click="login"
          >
            <span v-if="!laoding">{{ $t('connect') }}</span>
            <pulse-loader
              v-if="laoding"
              color="#fff"
            />
          </button>
        </div>
      </div>
      <div class="flex-1 h-full w-1/2 second-bloc">
        <div class="bloc-picture fixed">
          <img
            :src="vector"
          >
          <img
            :src="group"
            class="groupe object-center"
          >
          <img
            :src="ellipse"
            class="ellipse"
          >
        </div>
      </div>
    </div>
    <reset-password-search v-if="wanToRessetPass" @sucessEmail="sucessEmail" @searchClose="searchClose"/>
    <reset-email-sended  v-if="passEmailSend" @closeReset="closeReset"/>
  </div>
</template>

<script>
import firebase from 'firebase'
import TheHeader from '../components/the-header'
import group from '../assets/images/group.svg'
import group1 from '../assets/images/ellipse.svg'
import group2 from '../assets/images/vector.svg'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import ResetPasswordSearch from '../components/helper/rema-components/reset-password-search'
import ResetEmailSended from '../components/helper/rema-components/reset-email-sended'
export default {
  name: 'Login',
  components: {
    ResetEmailSended,
    ResetPasswordSearch,
    PulseLoader,
    TheHeader
  },
  data () {
    return {
      email: '',
      password: '',
      group: group,
      ellipse: group1,
      vector: group2,
      passIconColor: '',
      emailInputColor: '',
      laoding: false,
      defaultColor: '#BEBEBE',
      wantToseeConfirmPass: false,
      showInput: 'password',
      passEmailSend: false,
      wanToRessetPass: false
    }
  },
  methods: {
    login () {
      this.laoding = true
      let vm = this
      firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        .then(
          () => {
            vm.laoding = false
            vm.$router.replace('dashboard')
          }
        )
        .catch(
          (err) => {
            vm.laoding = false
            alert(err.toString())
          })
    },
    changeInputColor () {
      if (this.passIconColor === '') {
        this.passIconColor = '#000'
      }
    },
    changeEmailColr () {
      if (this.emailInputColor === '') {
        this.emailInputColor = '#000'
      }
    },
    isNumberOnly ($event) {
      if (this.inputType === 'tel') {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault()
        }
      }
    },
    showConfPass () {
      this.wantToseeConfirmPass = true
      this.showInput = 'text'
    },
    hideConfPass () {
      this.wantToseeConfirmPass = false
      this.showInput = 'password'
    },
    forgetPass () {
      this.wanToRessetPass = true
    },
    sucessEmail (answer) {
      this.passEmailSend = answer
      this.wanToRessetPass = false
    },
    searchClose (answer) {
      this.wanToRessetPass = answer
    },
    closeReset (answer) {
      this.passEmailSend = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/sass/mixin";
.login {
  overflow: hidden;
  height: 100%;
  .connect, .createPage, .forgetPass {
    color: $Green-Rema;
  }
  .input {
    width: 2rem!important;
  }
  .buttonLogin, .bloc-picture {
    background-color: $Green-Rema;
  }
  .groupe {
    position: relative;
    top: -13rem;
    left: 5rem;
    z-index: 1;
  }
  .ellipse {
    position: relative;
    top: -45rem;
    left: 25rem;
  }
}
@media (max-width: 600px) {
  .second-bloc {
    display: none;
  }
  .margin-head {
    padding-top: 2rem;
  }
  .div-padding {
    padding-left: 3.3rem;
    padding-right: 3.3rem;
  }
  .createPage {
    display: block;
    margin-top: .5rem;
  }
  .buttonLogin {
    width: 100%;
    height: 4rem;
  }
  input {
    width: 100% !important;
  }
  .input-container {
    width: 100%;
  }
}
</style>
